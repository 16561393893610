import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { RecoilRoot } from 'recoil';
import VerifyConnect from 'hooks/Connection';
import ColorSwitcher from 'components/Common/ColorSwitcher';
import { isMultiColorActive } from 'constants/defaultValues';
import Loading from 'components/Loading';
import Routers from './router';

const Offline = React.lazy(() => import('components/Offline'));

function App() {
  const [t] = useTranslation();
  const connection = VerifyConnect();

  return (
    <>
      <RecoilRoot>
        {isMultiColorActive && <ColorSwitcher />}
        <Suspense fallback={<Loading text={t('loading')} />}>
          <Offline hidden={connection} text={t('offline')} />
          <Routers />
        </Suspense>
      </RecoilRoot>
    </>
  );
}

export default App;
