import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthGuardActive } from 'constants/defaultValues';
import GetUser from 'hooks/GetUser';

const ProtectedRoute = ({ component: Component, roles = undefined, ...rest }) => {
  const currentUser = GetUser();

  const setComponent = (props) => {
    if (isAuthGuardActive) {
      if (currentUser) {
        if (roles) {
          if (roles.includes(currentUser.role)) {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={setComponent} />;
};
const UserRole = {
  Paciente: 0,
  MedicoExecutante: 1,
  MedicoSolicitante: 2,
};

export { ProtectedRoute, UserRole };
