// Hook
import { useState, useEffect } from 'react';

export default function VerifyConnect() {
  const [connection, setConnection] = useState(true);
  function offline() {
    // alert('off');
    setConnection(false);
  }

  function online() {
    // alert('on');
    setConnection(true);
  }

  function checkOnline() {
    if (navigator.onLine) {
      online();
    } else {
      offline();
    }
  }
  useEffect(() => {
    checkOnline();
  });

  window.addEventListener('offline', () => offline());
  window.addEventListener('online', () => online());

  // setCheckConnetion({ ip: connection });

  return connection;
}
