import {
  defaultColor,
  loginImageKey,
  themeColorStorageKey,
  themeRadiusStorageKey,
  userStorageKey,
} from 'constants/defaultValues';

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toGMTString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
  return true;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const getCurrentRadius = () => {
  let currentRadius = 'flat';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentRadius > error', error);
    currentRadius = 'flat';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentRadius > error', error);
  }
};

export const getLoginImage = () => {
  let user = null;
  try {
    user = localStorage.getItem(loginImageKey) != null ? JSON.parse(localStorage.getItem(loginImageKey)) : null;
  } catch (error) {
    console.log('>>>>: src/helpers/utils.js  : getLoginImage > error', error);
    user = null;
  }
  return user;
};

export const setLoginImage = (user) => {
  try {
    if (user) {
      localStorage.setItem(loginImageKey, JSON.stringify(user));
    } else {
      localStorage.removeItem(loginImageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/utils.js : setLoginImage > error', error);
  }
};

export const removeUser = () => {
  localStorage.removeItem(userStorageKey);
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user = localStorage.getItem(userStorageKey) != null ? JSON.parse(localStorage.getItem(userStorageKey)) : null;
  } catch (error) {
    console.log('>>>>: src/helpers/utils.js  : getCurrentUser > error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem(userStorageKey, JSON.stringify(user));
    } else {
      localStorage.removeItem(userStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/utils.js : setCurrentUser > error', error);
  }
};

export async function getCurrentColor() {
  let currentColor = defaultColor;

  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey) ?? currentColor;
      return currentColor;
    } else {
      return currentColor;
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor > error', error);
    currentColor = defaultColor;
    return currentColor;
  }
}

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor > error', error);
  }
};
