import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const customLanguageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback) => {
    const language = localStorage.getItem('i18nextLng') || navigator.language;
    if (language === 'pt' || language === 'pt-PT') {
      callback('pt-BR');
    } else {
      callback(language);
    }
  },
  init: () => {},
  cacheUserLanguage: (lng) => {
    localStorage.setItem('i18nextLng', lng);
  },
};

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(customLanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: {
      'pt-BR': ['pt-BR'],
      'es-AR': ['es-ES'],
      'es-PA': ['es-ES'],
      'es-UY': ['es-ES'],
      'es-PE': ['es-ES'],
      'es-CL': ['es-ES'],
      'es-MX': ['es-ES'],
    },
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: [
        'customLanguageDetector',
        'localStorage',
        'cookie',
        'querystring',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
