import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';

const Loader = () => <div className="" />;

ReactDOM.render(
  <>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </>,
  document.getElementById('root'),
);
