import { atom } from 'recoil';

export const keys = {
  CURRENT_USER: 'vivace-portal-exame/user/CURRENT_USER',
};

export const currentUser = atom({
  key: keys.CURRENT_USER,
  default: {
    name: '',
    profile: null,
    prontuario: '',
    role: null,
  },
});
