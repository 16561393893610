import React from 'react';

function Loading({ height, width, text }) {
  return (
    <div className="overlay">
      <div className="loading-text">
        <div className="content-load">
          <label>{text}</label>
          <svg width={width} height={height} viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        </div>
      </div>
    </div>
  );
}

Loading.defaultProps = {
  width: '48px',
  height: '48px',
};

export default Loading;
