import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { adminRoot } from './constants/defaultValues';
import { ProtectedRoute, UserRole } from './helpers/auth';

// Pages
const ViewHome = React.lazy(() => import('./views/home'));
const ViewValidate = React.lazy(() => import('./views/home/validate'));
const ViewRecovery = React.lazy(() => import('./views/home/recovery'));
const ViewExame = React.lazy(() => import('./views/home/exame'));
const ViewMFA = React.lazy(() => import('./views/home/mfa'));
const ViewApp = React.lazy(() => import('./views/app'));
const ViewLink = React.lazy(() => import('./views/link'));

// const ViewError = React.lazy(() => import('./views/error'));
// const ViewUnauthorized = React.lazy(() => import('./views/unauthorized'));

export default function Routers() {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute path={adminRoot} component={ViewApp} roles={[UserRole.Admin, UserRole.Editor]} />
        <Route path="/login" exact render={(props) => <ViewHome {...props} />} />
        <Route path="/login/:tab" exact render={(props) => <ViewHome {...props} />} />
        <Route path="/validate/:data" exact render={(props) => <ViewValidate {...props} />} />
        <Route path="/validate/:token/:name/" exact render={(props) => <ViewValidate {...props} />} />
        <Route path="/laudo/:token" render={(props) => <ViewExame {...props} />} />
        <Route path="/recovery/" render={(props) => <ViewRecovery {...props} />} />
        <Route path="/mfa" render={(props) => <ViewMFA {...props} />} />
        <Route path="/link" exact render={(props) => <ViewLink {...props} />} />
        <Route path="/link/:id" render={(props) => <ViewLink {...props} />} />
        <Route path="/link/:msg/:id" render={(props) => <ViewLink {...props} />} />
        <Route path="/exame/:id" render={(props) => <ViewLink {...props} />} />
        <Redirect exact from="/" to={adminRoot} />
      </Switch>
    </BrowserRouter>
  );
}
