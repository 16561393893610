// Hook
import * as User from 'atomos/user/atomo';
import { useRecoilValue } from 'recoil';
import { getCurrentUser } from 'helpers/utils';

export default function GetUser() {
  const currentUserState = useRecoilValue(User.currentUser);
  const storageUser = getCurrentUser();
  const currentUser = !currentUserState.user ? storageUser : currentUserState;
  return currentUser;
}
