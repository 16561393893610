import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'rc-switch/assets/index.css';
import { isMultiColorActive, defaultColor, isDarkSwitchActive } from 'constants/defaultValues';
import { getCurrentColor, setCurrentColor } from 'helpers/utils';

const active = isMultiColorActive || isDarkSwitchActive;

async function getColor(value) {
  let color = defaultColor;

  if (value) {
    color = await getCurrentColor();
  }
  setCurrentColor(color);
  return color;
}

const render = async () => {
  const color = await getColor(active);
  import(`./assets/css/sass/themes/${color}.scss`).then(() => {
    require('./AppRender');
  });
};

render();
