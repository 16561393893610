export const adminRoot = '/app';
let currentApiUrl = `${window.location.protocol}//${window.location.host}`;
if (process.env.REACT_APP_AMBIENTE && process.env.REACT_APP_AMBIENTE !== 'deploy') {
  currentApiUrl = process.env.REACT_APP_AMBIENTE;
}
export const development = process.env.NODE_ENV === 'development';
export const hasLab = false;
export const timeout = 30000;
const name = process.env.REACT_APP_NAME;
const version = process.env.REACT_APP_VERSION;
export const apiUrl = currentApiUrl;
export const searchPath = `${adminRoot}/#`;
export const isAuthGuardActive = true;
export const currentUser = undefined;
export const urlStaticFiles = `${apiUrl}/static/media/`;
// Theme
export const isMultiColorActive = false;
export const cookiesName = `__cookies_${name}_${version}`;
export const tokenName = `__token_${name}_${version}`;
export const defaultColor = 'light.theme-1';
export const themeColorStorageKey = `__theme_selected_color_${name}_${version}`;
export const themeRadiusStorageKey = `__theme_radius_${name}_${version}`;
export const userStorageKey = `${name}_current_user_${version}`;
export const loginImageKey = `${name}_login_image_${version}`;
export const isDarkSwitchActive = true;
export const localeOptions = [
  { value: 'pt-BR', label: 'Brasil', flag: 'br' },
  { value: 'es-AR', label: 'Argentina', flag: 'ar' },
  { value: 'es-CL', label: 'Chile', flag: 'cl' },
  { value: 'es-MX', label: 'México', flag: 'mx' },
  { value: 'es-PA', label: 'Panamá', flag: 'pa' },
  { value: 'es-PE', label: 'Peru', flag: 'pe' },
  { value: 'es-UY', label: 'Uruguai', flag: 'uy' },
  { value: 'es-ES', label: 'Espanha', flag: 'es' },
];
export const colors = [
  'theme-1',
  'theme-2',
  'theme-3',
  'theme-4',
  'theme-5',
  'theme-6',
  'theme-7',
  'theme-8',
  'theme-9',
  'theme-10',
];
